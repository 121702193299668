

































































































































































































































































































































































import { ResponsiveMixin } from "@/mixins/Responsive";
import Component, { mixins } from "vue-class-component";
import DialogDatePicker from "@/components/Inputs/DialogDatePicker.vue";
import EmailIcon from "@/assets/icons/saxcons/sms-linear.svg";
import UsersAutoComplete from "../Navigation/UsersAutoComplete.vue";
import PriceIcon from "@/assets/icons/saxcons/dollar-square-broken.svg";
import {
  SportangoTextField,
  SportangoSelect,
  SportangoTextArea,
  SportangoAutocomplete
} from "@/components/Inputs/overrides";
import PictureUploadButton from "@/components/users/PictureUploadButton.vue";
import { BaseUser, StripeMerchantInfo } from "@sportango/backend";
import { ProfileForm } from "@/mixins/Profile";
import { CurrentUserMixin, LoadingMixin } from "@/mixins/Helpers";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
import { AutoCompleteItem } from "../Inputs/mixins";
import PlusIcon from "@/assets/icons/saxcons/add-linear.svg";
import { Header } from "@/types/Table";
import { ICoachHeaders } from "@/types/UserProfile";
import AppDialog from "@/components/Navigation/AppDialog.vue";
import { Watch } from "vue-property-decorator";
import EditIcon from "@/assets/icons/saxcons/edit-linear.svg";
import DeleteIcon from "@/assets/icons/saxcons/trash-linear.svg";
import Menu from "@/assets/icons/saxcons/three-dots-vertical.svg";
import { WatchLoading } from "@/decorators/Loading";
import MultiInput from "@/components/Inputs/MultiInput.vue";
interface coachPricing {
  uid: string;
  displayName: string | undefined;
  price?: number;
}
@Component({
  name: "user-profile-edit-form",
  components: {
    MultiInput,
    PriceIcon,
    UsersAutoComplete,
    DialogDatePicker,
    SportangoTextField,
    SportangoSelect,
    SportangoTextArea,
    SportangoAutocomplete,
    EmailIcon,
    PictureUploadButton,
    LottieAnimation,
    PlusIcon,
    AppDialog,
    EditIcon,
    DeleteIcon,
    Menu
  }
})
export default class UserProfileEditForm extends mixins(
  ResponsiveMixin,
  ProfileForm,
  CurrentUserMixin,
  LoadingMixin
) {
  // Coach pricing information variables
  showDialog = false;
  isFormValid = false;
  get coachHeaders(): Array<Header<ICoachHeaders>> {
    return [
      {
        value: "displayName",
        text: "Coach"
      },
      {
        value: "price",
        text: "Price"
      },
      {
        value: "actions",
        text: "Actions",
        sortable: false,
        align: "end"
      }
    ];
  }

  get shouldShowCoachPricing(): boolean {
    if (!this.currentUser) {
      return false;
    }
    if (this.$store.getters.featureFlags["coach-merchants"]) {
      return (
        this.currentUser.permissions.hasCoachAccess ||
        this.currentUser.permissions.hasAdminAccess
      );
    } else {
      return this.currentUser?.permissions.hasAdminAccess;
    }
  }
  coachId = "";
  coachPrice: number | null = null;

  @Watch("showDialog")
  performAction(v: boolean) {
    if (!v) this.reset();
  }
  @WatchLoading()
  saveAndHide() {
    if (
      this.$store.getters.featureFlags["coach-merchants"] &&
      this.currentUser?.permissions.hasCoachAccess
    ) {
      this.coachId = this.currentUser.uid;
    }
    if (this.coachPrice && this.coachId) {
      if (this.userProfileInfo.coachPricing) {
        Object.assign(this.userProfileInfo.coachPricing, {
          [this.coachId]: this.coachPrice
        });
      } else {
        this.userProfileInfo.coachPricing = {
          [this.coachId]: this.coachPrice
        };
      }
    }
    this.save();
    this.reset();
  }
  @WatchLoading()
  deleteCoachPricing(uid: string): void {
    if (this.userProfileInfo.coachPricing) {
      delete this.userProfileInfo.coachPricing[uid];
      this.save();
    }
  }
  editCoachPricing(uid: string, price: number): void {
    this.coachId = uid;
    this.coachPrice = price;
    this.showDialog = true;
  }
  reset(): void {
    this.coachId = "";
    this.coachPrice = null;
    const refForm: any = this.$refs.form;
    refForm.reset();
    this.showDialog = false;
  }
  get discountItems(): Array<AutoCompleteItem> {
    if (this.merchantInfo && this.merchantInfo.discounts) {
      return this.merchantInfo.discounts.map((e, i) => {
        return {
          value: `${i}|${e.value}`,
          text: `${e.name}-${e.value}%`
        };
      });
    }
    return [];
  }

  get discountAppliedComp() {
    const discountInfo = this.userProfileInfo.discountApplied;
    if (this.merchantInfo && this.merchantInfo?.discounts && discountInfo) {
      const idx = this.merchantInfo?.discounts.findIndex((e) => {
        const discountVal = Number(e.value);
        return (
          discountVal === discountInfo.value && e.name === discountInfo.name
        );
      });
      return `${idx}|${discountInfo.value}`;
    }
    return "";
  }
  set discountAppliedComp(val: string) {
    if (val === null || val === undefined) {
      this.userProfileInfo.discountApplied = undefined;
    } else if (this.merchantInfo && this.merchantInfo?.discounts) {
      const parts = val.split("|").map((e) => Number(e));
      this.userProfileInfo.discountApplied = {
        value: parts[1],
        name: this.merchantInfo.discounts[parts[0]].name,
        appliedAt: new Date(),
        appliedBy: this.currentUser?.uid || ""
      };
    }
  }
  get merchantInfo(): StripeMerchantInfo | undefined {
    return this.$store.getters.merchantInfo;
  }
  get userLoaded(): boolean {
    return this.user !== undefined && this.currentUser !== undefined;
  }
  get coaches(): Array<BaseUser> {
    return this.$store.state.users.filter((user) => {
      if (user.permissions.hasCoachAccess) {
        return user;
      }
    });
  }

  get showAddCoachPricingButton(): boolean {
    if (this.currentUser) {
      if (this.$store.getters.featureFlags["coach-merchants"]) {
        return !this.currentUser.permissions.hasCoachAccess;
      }
    }
    return true;
  }

  get coachesWithPricing(): Array<coachPricing> {
    if (this.userProfileInfo.coachPricing) {
      let coachPricingArr = [] as Array<coachPricing>;
      const keys = Object.keys(this.userProfileInfo.coachPricing);
      if (
        this.$store.getters.featureFlags["coach-merchants"] &&
        this.currentUser?.permissions.hasCoachAccess
      ) {
        coachPricingArr = [
          {
            uid: this.currentUser.uid,
            displayName: this.currentUser.displayName || "----",
            price:
              this.userProfileInfo.coachPricing[this.currentUser.uid] ||
              this.currentUser.additionalInfo?.hourlyPrivatePrice ||
              0
          }
        ];
      } else {
        this.coaches.forEach((e) => {
          if (keys.includes(e.uid)) {
            coachPricingArr.push({
              uid: e.uid,
              displayName: e.displayName || "-",
              price: this.userProfileInfo.coachPricing
                ? this.userProfileInfo.coachPricing[e.uid]
                : 0
            });
          }
        });
      }
      return coachPricingArr;
    }
    return [];
  }
}
