












import Component, { mixins } from "vue-class-component";
import { CurrentUserMixin, EmitClose, LoadingMixin } from "@/mixins/Helpers";
import UserProfileEditForm from "@/components/users/UserProfileEditForm.vue";
import { BaseUser } from "@sportango/backend";
import AppDialog from "@/components/Navigation/AppDialog.vue";
import { ResponsiveMixin } from "@/mixins/Responsive";

@Component({
  name: "user-profile",
  components: {
    AppDialog,
    UserProfileEditForm
  }
})
export default class Profile extends mixins(
  LoadingMixin,
  CurrentUserMixin,
  ResponsiveMixin,
  EmitClose
) {
  private gotError = false;
  get showStripeButton(): boolean {
    if (
      this.currentUser?.permissions?.hasCoachAccess ||
      this.currentUser?.permissions?.hasAdminAccess
    ) {
      return false;
    }
    return true;
  }
  get userForForm(): BaseUser | undefined {
    return this.usersToConsider.find((u) => u.uid === this.$route.params.id);
  }

  get usersToConsider(): Array<BaseUser> {
    if (this.$route.fullPath.includes("players")) {
      return this.$store.getters.coachPlayers;
    }
    return this.$store.getters.users;
  }

  get dialogWidth(): number | string {
    if (this.isDesktop) {
      return innerWidth * 0.5;
    }
    return "100%";
  }
}
